import React, { useEffect } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { DOMAIN, SITE_NAME } from '@shared-submodule/sm-constants'
import { checkPageNumberInSlug, makeCanonical } from '@shared-submodule/utils'

import dynamic from 'next/dynamic'
const AllSchema = dynamic(() => import('@shared-submodule/sm-components/all-schema'))
const MetaTags = dynamic(() => import('@shared-submodule/sm-components/metaTags'))
// import MetaTags from '@shared-submodule/sm-components/metaTags'

const Layout = ({ children, isPreviewMode, data = {}, matchDetail, scoreCard, customAmpEnable }) => {
  const router = useRouter()
  const {
    lastSlug,
    slug
  } = checkPageNumberInSlug(router?.asPath?.split('/').filter((e) => e))
  const canonical = makeCanonical(data, router?.asPath)
  const ampURL = `${DOMAIN}amp/${slug?.join('/')}`

  function getDocumentTitle() {
    const t = (data?.oSeo?.sTitle || data?.sTitle) || SITE_NAME
    if (data?.oListicleArticle?.oPageContent?.length > 0) {
      return Number(lastSlug) ? `Page ${Number(lastSlug)}: ${t}` : t
    }
    return t
  }

  useEffect(() => {
    const ampLink = document.getElementById('ampURL')
    if (ampLink && (data?.oSeo?.eType === 'ar' || data?.oSeo?.eType === 'fa')) {
      ampLink.href = ampURL
    } else if (ampLink) {
      ampLink?.remove()
    }
  }, [router?.asPath])

  return (
    <>
      <Head>
        <title>{getDocumentTitle()}</title>
        {/* <link rel="preconnect" href="https://fonts.googleapis.com" /> */}
        {/* <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" /> */}
        {/* <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@500;600;700;800&display=swap" rel="stylesheet" /> */}
        <link rel="canonical" href={canonical} />
        {/* Disabled AMP for now */}
        {/* {(((data?.oSeo?.eType === 'ar' || data?.oSeo?.eType === 'fa') && data?.oAdvanceFeature?.bAmp) || customAmpEnable) && (
          <link rel="amphtml" id="ampURL" href={ampURL} />
        )} */}
        {/* ar,ct,gt=general tag,p=player,t=team,v=venu,fa */}
        <MetaTags title={getDocumentTitle()} data={data} canonical={canonical} router={router} />
        <AllSchema data={data} matchDetail={matchDetail} scoreCard={scoreCard} router={router} />
      </Head>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  isPreviewMode: PropTypes.bool,
  data: PropTypes.object,
  matchDetail: PropTypes.object,
  scoreCard: PropTypes.array,
  customAmpEnable: PropTypes.bool
}

export default Layout
